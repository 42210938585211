import * as React from "react";
import {ReactNode} from "react";
import {Constants} from "@folksam-digital/model";
import {
    AccidentIcon,
    BackPackIcon,
    BicycleIcon,
    CaravanIconLarge,
    CarIcon,
    CatIcon,
    DateCalendarLargeIcon,
    DogIcon,
    EpaAndATractorIcon,
    ForestIcon,
    GiftBoxIcon,
    HeavyMotorcycleLarge,
    HomeBuildingIcon,
    HomeGeneralIcon,
    HomeVacationHeaderIcon,
    HouseIcon,
    IllnessLargeIcon,
    InstalledHeaterIcon,
    LightMotorcycleIcon,
    MobileHomeHIcon,
    MobilePhoneIcon,
    MopedCarHIcon,
    MopedIcon,
    PersonInsuranceIcon,
    PregnancyLargeIcon,
    SmallTractorIcon,
    SnowmobileAtvHIcon,
    SportsTravelBagIcon,
    StopwatchIcon,
    TeddyBearIcon,
    TrailerIcon,
    TravelHeaderIcon,
    TruckHIcon,
    HomeAddOnsHeader,
    ElectricScooterIcon
} from "@folksam-digital/ui";

export const HeaderLayoutNewIconMap: Record<string, ReactNode> = {
    [Constants.Journey.Cat.Id]: <CatIcon/>,
    [Constants.Journey.CatClaim.Id]: <CatIcon/>,
    [Constants.Journey.Dog.Id]: <DogIcon/>,
    [Constants.Journey.DogClaim.Id]: <DogIcon/>,
    [Constants.Journey.LightTruck.Id]: <TruckHIcon/>,
    [Constants.Journey.EpaAndATractor.Id]: <EpaAndATractorIcon/>,
    [Constants.Journey.MopedCar.Id]: <MopedCarHIcon/>,
    [Constants.Journey.LightElectricalVehicle.Id]: <ElectricScooterIcon/>,
    [Constants.Journey.SmallTractor.Id]: <SmallTractorIcon/>,
    [Constants.Journey.Car.Id]: <CarIcon/>,
    [Constants.Journey.MemberChild.Id]: <TeddyBearIcon/>,
    [Constants.Journey.ShoppingCart.Id]: <GiftBoxIcon/>,
    [Constants.Journey.HomeRental.Id]: <HouseIcon/>,
    [Constants.Journey.HomeBuilding.Id]: <HomeBuildingIcon/>,
    [Constants.Journey.HomeStudent.Id]: <BackPackIcon/>,
    [Constants.Journey.HomeApartment.Id]: <HouseIcon/>,
    [Constants.Journey.HomeVacation.Id]: <HomeVacationHeaderIcon/>,
    [Constants.Journey.OptInOptOut.Id]: <GiftBoxIcon/>,
    [Constants.Journey.HomeCondominium.Id]: <HouseIcon/>,
    [Constants.Journey.HomeAddOns.Id]: <HomeAddOnsHeader/>,
    [Constants.Journey.SnowmobileAtv.Id]: <SnowmobileAtvHIcon/>,
    [Constants.Journey.MobileHome.Id]: <MobileHomeHIcon/>,
    [Constants.Journey.Trailer.Id]: <TrailerIcon/>,
    [Constants.Journey.Moped.Id]: <MopedIcon/>,
    [Constants.Journey.LightMotorcycle.Id]: <LightMotorcycleIcon/>,
    [Constants.Journey.HeavyMotorcycle.Id]: <HeavyMotorcycleLarge/>,
    [Constants.Journey.PersonalAccident.Id]: <AccidentIcon/>,
    [Constants.Journey.HeavyMotorcycle.Id]: <HeavyMotorcycleLarge/>,
    [Constants.Journey.Caravan.Id]: <CaravanIconLarge/>,
    [Constants.Journey.PregnancyComplications.Id]: <PregnancyLargeIcon/>,
    [Constants.Journey.Illness.Id]: <IllnessLargeIcon/>,
    [Constants.Journey.Incapacity.Id]: <PersonInsuranceIcon/>,
    [Constants.Journey.InstalledHeater.Id]: <InstalledHeaterIcon/>,
    [Constants.Journey.AccidentDuringTravel.Id]: <PersonInsuranceIcon/>,
    [Constants.Journey.Bicycle.Id]: <BicycleIcon/>,
    [Constants.Journey.Burglary.Id]: <HomeGeneralIcon/>,
    [Constants.Journey.BurglaryInVacationHome.Id]: <HomeGeneralIcon/>,
    [Constants.Journey.CancelledTravel.Id]: <TravelHeaderIcon/>,
    [Constants.Journey.DelayedLuggage.Id]: <TravelHeaderIcon/>,
    [Constants.Journey.DelayedTraveler.Id]: <TravelHeaderIcon/>,
    [Constants.Journey.Fire.Id]: <HomeGeneralIcon/>,
    [Constants.Journey.FireInVacationHome.Id]: <HomeGeneralIcon/>,
    [Constants.Journey.Glass.Id]: <HomeGeneralIcon/>,
    [Constants.Journey.GlassInVacationHome.Id]: <HomeGeneralIcon/>,
    [Constants.Journey.HouseholdAppliances.Id]: <HomeGeneralIcon/>,
    [Constants.Journey.HouseholdAppliancesInVacationHome.Id]: <HomeGeneralIcon/>,
    [Constants.Journey.IllnessDuringTravel.Id]: <PersonInsuranceIcon/>,
    [Constants.Journey.MobilePhone.Id]: <MobilePhoneIcon/>,
    [Constants.Journey.VehicleCollisionWithAnimal.Id]: <CarIcon/>,
    [Constants.Journey.VehicleCollision.Id]: <CarIcon/>,
    [Constants.Journey.VehicleEngineDamage.Id]: <CarIcon/>,
    [Constants.Journey.VehicleFire.Id]: <CarIcon/>,
    [Constants.Journey.VehicleHitAndRunAccident.Id]: <CarIcon/>,
    [Constants.Journey.VehicleInteriorDamage.Id]: <CarIcon/>,
    [Constants.Journey.VehicleLossOfKey.Id]: <CarIcon/>,
    [Constants.Journey.VehicleMisfuelling.Id]: <CarIcon/>,
    [Constants.Journey.VehicleSingleAccident.Id]: <CarIcon/>,
    [Constants.Journey.VehicleVandalism.Id]: <CarIcon/>,
    [Constants.Journey.VehicleTheftOrBurglary.Id]: <CarIcon/>,
    [Constants.Journey.Nature.Id]: <ForestIcon/>,
    [Constants.Journey.NatureInVacationHome.Id]: <ForestIcon/>,
    [Constants.Journey.OtherDamageOrLoss.Id]: <HomeGeneralIcon/>,
    [Constants.Journey.Theft.Id]: <HomeGeneralIcon/>,
    [Constants.Journey.TheftInVacationHome.Id]: <HomeGeneralIcon/>,
    [Constants.Journey.Water.Id]: <HomeGeneralIcon/>,
    [Constants.Journey.WaterInVacationHome.Id]: <HomeGeneralIcon/>,
    [Constants.Journey.Storm.Id]: <HomeGeneralIcon/>,
    [Constants.Journey.StormInVacationHome.Id]: <HomeGeneralIcon/>,
    [Constants.Journey.Accident.Id]: <AccidentIcon/>,
    [Constants.Journey.AccidentAndIllness.Id]: <IllnessLargeIcon/>,
    [Constants.Journey.RunningRace.Id]: <StopwatchIcon/>,
    [Constants.Journey.IndividualChild.Id]: <TeddyBearIcon/>,
    [Constants.Journey.Pregnancy.Id]: <PregnancyLargeIcon/>,
    [Constants.Journey.IncomeAddon.Id]: <DateCalendarLargeIcon/>,
    [Constants.Journey.IncomeChange.Id]: <DateCalendarLargeIcon/>,
    [Constants.Journey.SportsTravel.Id]: <SportsTravelBagIcon/>,
    [Constants.Journey.AccidentSpareTime.Id]: <AccidentIcon/>,
};
