import {extractQueryParams} from "../../Helpers/extractQueryParams";
import {IInitParams, StorageKeys} from "../../services";
import isEmpty from "lodash/isEmpty";
import React, {useState} from "react";
import {useLocation} from "react-router";
import {IToggleProps} from "./featureToggle/withToggle";

const withIncomeInsuranceParams = <P extends IToggleProps>(dependencies: any) => {
    return (WrappedComponent: React.ComponentType<P>) => (props: P) => {
        const location = useLocation();
        useState(() => {
            initIncomeInsuranceParams({...dependencies, location});
            return true;
        });

        return <WrappedComponent {...props}/>;
    }
};

const initIncomeInsuranceParams = ({sessionStorage, location}: any): void => {
    const param = extractQueryParams(["id"], location.search) as IInitParams;

    if (param?.id) {
        const parts = param?.id?.split("-");
        param.id = parts[0];
        param.commonGroupId = parts[0];
        param.type = parts[1] as "TILLAGGLON" | "TILLAGGTID";
    }

    if (!isEmpty(param)) {
        sessionStorage.set(StorageKeys.PARAMS, param);
    }
}

const initParams = ({sessionStorage, location}: any): void => {
    let params: IInitParams = {};
    if (location.search) {
        params = extractQueryParams(["workFlowEnv", "agentId", "dynamicBackUrl", "completionQueryString"], location.search) as IInitParams;
    }

    sessionStorage.set(StorageKeys.PARAMS, params);
}

const withInitParams = <P extends IToggleProps>(dependencies: any) => {
    return (WrappedComponent: React.ComponentType<P>) => (props: P) => {
        const location = useLocation();
        useState(() => {
            initParams({...dependencies, location});
            return true;
        });

        return <WrappedComponent {...props}/>;
    }
};

export {withIncomeInsuranceParams, withInitParams};
