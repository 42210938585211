import * as React from "react";
import {withRouter} from "react-router-dom";
import flowRight from "lodash/flowRight";
import isEmpty from "lodash/isEmpty";
import {Constants} from "@folksam-digital/model";
import {ConstrainWidth, Grid, MaxWidthWrapper, Spacing, Step} from "@folksam-digital/ui";
import ClaimSuccessBase, {IClaimSuccessBaseProps} from "./ClaimSuccessBase";
import {BicycleJourney} from "@folksam-digital/model/lib/journey/claim/BicycleJourney";
import {IOnTriggerDataLayerEvent} from "../../../../analytics/new/BaseAnalytics";
import {FormContext, IFormContext} from "../../../../components/journey/form/FormContext";
import withCmsProvider from "../../../../components/general/withCmsProvider";
import withDataAnalytics from "../../../../components/general/withDataAnalytics";
import withHistoryBackConstraint from "../../../../components/general/withHistoryBackConstraint";
import {CmsContext, ICmsContext} from "../../../../cms";
import {CloseModalButton} from "../../../../components/journey/navigation/loggedIn/CloseModalButton";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {CompensationsTable} from "../common/CompensationsTable";
import {ComponentBackgroundWrapper} from "../../../../components/ComponentBackgroundWrapper";
import {SuccessHeaderLayout} from "../../../../components/journey/layout/Success/SuccessHeaderLayout";
import {SuccessHeaderMessageBox} from "../../../../components/journey/layout/Success/SuccessHeaderMessageBox";
import {SuccessHeaderTable} from "../../../../components/journey/layout/Success/SuccessHeaderTable";
import {FormattedMessage} from "react-intl";
import {AnalyticsType, getAnalytics} from "../../../../analytics/AnalyticsSwitch";

interface BicycleClaimSuccessProps extends IClaimSuccessBaseProps, IOnTriggerDataLayerEvent {

}

class BicycleClaimSuccessInternal extends ClaimSuccessBase<BicycleJourney, BicycleClaimSuccessProps> {
    public static contextType = CmsContext;
    protected prefix = "claim.bicycle";

    constructor(props: BicycleClaimSuccessProps, context?: ICmsContext) {
        super(props, Constants.Journey.Bicycle.Id, context);

        this.getTotalCompensationValue = this.getTotalCompensationValue.bind(this);
    }

    public componentDidMount(): void {
        this.checkData(Constants.Journey.Bicycle.Id);
        this.triggerDataLayerEvent(Constants.Journey.Bicycle.Id);
    }

    public render() {
        const data = this.data;

        if (isEmpty(data)) {
            return <></>;
        }

        return (
            <FormContext.Provider value={{data: data} as IFormContext}>
                <React.Fragment>
                    <SuccessHeaderLayout
                        bannerIconTheme={Constants.SuccessPageHeaderProperties.ImageTheme.Claim}
                        journeyId={Constants.Journey.Bicycle.Id}
                        formData={{contact: data.informant}}
                        backUrl={Constants.Journey.Bicycle.backUrl}
                        navButton={CloseModalButton}
                        translations={{
                            header: "general.success.banner.heading",
                            subheader: super.getBannerSubheaderText(CmsHelper.withPrefix(this.context, "success.banner.subheading")),
                            headerText: <FormattedMessage
                                id={CmsHelper.withPrefix(this.context, "pageTitle.headerText")}/>
                        }}
                        doScroll={true}
                    >
                        <SuccessHeaderMessageBox
                            message={this.getSuccessMessageText()}/>
                        <SuccessHeaderTable claimNumber={data.claimNumber}
                                            email={!this.isSTPClaim(data) ? this.getContact()?.emailAddress : undefined}/>
                        <Spacing type={"padding"} bottom={'4'}/>
                        {this.renderMyPagesButton()}
                    </SuccessHeaderLayout>
                    <MaxWidthWrapper compact={true}>
                        <Step.SectionBody>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col md={12}>
                                        {this.renderCompensationCard(data)}
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Step.SectionBody>
                    </MaxWidthWrapper>
                    {/*Footer*/}
                    <ComponentBackgroundWrapper theme={1}>
                        <MaxWidthWrapper compact={true}>
                            <Step.Footer>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Col md={12}>
                                            {this.renderContactUsSection()}
                                        </Grid.Col>
                                    </Grid.Row>
                                </Grid>
                            </Step.Footer>
                        </MaxWidthWrapper>
                    </ComponentBackgroundWrapper>
                </React.Fragment>
            </FormContext.Provider>
        )
    }

    protected getSuccessMessageText(): string {
        return this.isSTPClaim(this.data) ? `${this.prefix}.success.stp.text.information` : super.getSuccessMessageText(`${this.prefix}.success.nostp.text.information`);
    }

    private getTotalCompensationValue() {
        return this.data?.compensationTotalAmount ? this.data.compensationTotalAmount : 0;
    }

    private renderCompensationCard(data: BicycleJourney): React.ReactNode {
        if (this.isSTPClaim(data)) {
            return (
                <Spacing type="padding" top="8" bottom="8">
                    <ConstrainWidth>
                        <CompensationsTable compensations={data.compensations}
                                            compensationCalcFn={this.getTotalCompensationValue}/>
                    </ConstrainWidth>
                </Spacing>
            );
        }
        return undefined;
    }
}

const BicycleClaimSuccess = flowRight(
    withCmsProvider("bicycle"),
    withDataAnalytics(getAnalytics(AnalyticsType.Claim)),
    withRouter,
    withHistoryBackConstraint
)(BicycleClaimSuccessInternal);

export default BicycleClaimSuccess
