import * as React from "react";
import flowRight from "lodash/flowRight";
import {CmsContext} from "../../cms";
import ErrorBase, {IErrorBaseState} from "../ErrorBase";
import withCmsProvider from "../../components/general/withCmsProvider";
import withDataAnalytics from "../../components/general/withDataAnalytics";
import {IOnTriggerDataLayerEvent, SatelliteEvents, SatelliteEventTransactionTypes} from "../../analytics/new/BaseAnalytics";
import FormattedMarkdown from "../../components/FormattedMarkdown";
import JourneyErrorPage from "./JourneyErrorPage";
import {StorageKeys} from "../../services";
import {CmsHelper} from "../../Helpers/cms/CmsHelper";
import {IErrorParams} from "./journeyErrorTypes";
import {JourneyErrorMessageDelegator} from "./product/model/JourneyErrorMessageDelegator";
import {ChooseTimeToCallBackComponent} from "../errorPage/ChooseTimeToCallBackComponent";
import {getAnalytics, AnalyticsType} from "../../analytics/AnalyticsSwitch";

export class JourneyErrorInternal<TProps extends IErrorParams & {
    errorType?: any
}, TState extends IErrorBaseState> extends ErrorBase<TProps & IOnTriggerDataLayerEvent, TState> {
    public static contextType = CmsContext;

    public get data(): TProps {
        return this.draftService.getDraft<TProps>(StorageKeys.JOURNEY_ERROR_DATA_KEY) as TProps;
    }

    public get params(): TProps {
        return this.draftService.getDraft<TProps>(StorageKeys.PARAMS) as TProps;
    }

    public async componentDidMount() {
        const message = this.prepareErrorMessage();

        this.setState({message: message.getMessage()});
        const data = this.data;
        // Trigger Analytics Error Lead Start
        this.props.onTriggerDataLayerEvent!({
            messages: this.context.catalog.messages,
            data: data?.defaultData,
            journeyId: data?.journeyId || "",
            currentStep: "error",
            transactionType: SatelliteEventTransactionTypes.Lead,
            event: SatelliteEvents.Start,
            errorMessage: message.getMessage()
        });
    }

    private prepareErrorMessage(): JourneyErrorMessageDelegator {
        return new JourneyErrorMessageDelegator(this.data ? this.data : {});
    }

    public render(): React.ReactNode {
        const data = this.data;
        const params = this.params;

        return (
            <JourneyErrorPage params={{...data, ...params}}>
                {!!this.state?.message &&
                    <FormattedMarkdown messageKey={CmsHelper.withPrefix(this.context, this.state.message)}/>}
                <ChooseTimeToCallBackComponent />
            </JourneyErrorPage>
        );
    }
}

const JourneyError = flowRight(
    withCmsProvider("error"),
    withDataAnalytics(getAnalytics(AnalyticsType.Product)),
)(JourneyErrorInternal);

export default JourneyError;
