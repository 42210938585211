import * as input from "./input";
import * as output from "./output";
import * as panel from "./panel";
import * as journeyContainer from "./containers";
import * as shoppingCartContainer from "../../shoppingCart/form/container";
import * as optInOptOutContainer from "../../optInOptOut/form/container";
import * as shoppingCartOutput from "../../shoppingCart/form/output";
import {IComponentsRegistry} from "./IComponentsRegistry";
import {ComponentType} from "@folksam-digital/model";

// Register component implementations
const ComponentsRegistry: IComponentsRegistry = {};

// Input components
ComponentsRegistry[ComponentType.TextBox] = input.TextBox;
ComponentsRegistry[ComponentType.PhoneNumberInput] = input.PhoneNumberInput;
ComponentsRegistry[ComponentType.CurrencyInputField] = input.CurrencyInputField;
ComponentsRegistry[ComponentType.CheckBox] = input.CheckBox;
ComponentsRegistry[ComponentType.ToggleButton] = input.ToggleButton;
ComponentsRegistry[ComponentType.RadioGroup] = input.RadioGroup;
ComponentsRegistry[ComponentType.CheckBoxGroup] = input.CheckBoxGroup;
ComponentsRegistry[ComponentType.DropDown] = input.DropDown;
ComponentsRegistry[ComponentType.DropDownMultiSelect] = input.DropDownMultiSelect;
ComponentsRegistry[ComponentType.YesNo] = input.YesNoButton;
ComponentsRegistry[ComponentType.CoverSelectionYesNoButton] = input.CoverSelectionYesNoButton;
ComponentsRegistry[ComponentType.AmountOptionRadioGroup] = input.AmountOptionRadioGroup;
ComponentsRegistry[ComponentType.PackageSelectionRadioGroup] = input.PackageSelectionRadioGroup;
ComponentsRegistry[ComponentType.Button] = input.ButtonWidget;
ComponentsRegistry[ComponentType.AddButton] = input.AddButton;
ComponentsRegistry[ComponentType.PackageSelectionCards] = input.PackageSelectionCards;
ComponentsRegistry[ComponentType.PackageSelectorCard] = input.PackageSelectorCard;
ComponentsRegistry[ComponentType.SlimPackageSelectionCards] = input.SlimPackageSelectionCards;
ComponentsRegistry[ComponentType.Date] = input.DateComponent;
ComponentsRegistry[ComponentType.Time] = input.TimeComponent;
ComponentsRegistry[ComponentType.GooglePlace] = input.GooglePlacesAutoComplete;
ComponentsRegistry[ComponentType.SearchInput] = input.SearchInput;
ComponentsRegistry[ComponentType.Textarea] = input.Textarea;
ComponentsRegistry[ComponentType.ModalYesNo] = input.ModalYesNoWidget;
ComponentsRegistry[ComponentType.MotorModalYesNo] = input.MotorModalYesNoWidget;
ComponentsRegistry[ComponentType.RedirectModalYesNoWidget] = input.RedirectModalYesNoWidget;
ComponentsRegistry[ComponentType.CarModalYesNo] = input.CarModalYesNoWidget;
ComponentsRegistry[ComponentType.ModalXPopup] = input.ModalXPopupWidget;
ComponentsRegistry[ComponentType.Hidden] = input.Hidden;
ComponentsRegistry[ComponentType.TeethSelectionComponent] = input.TeethSelectionComponent;
ComponentsRegistry[ComponentType.HeaderPanelInput] = input.HeaderPanelInput;
ComponentsRegistry[ComponentType.AnchorButton] = input.AnchorButton;
ComponentsRegistry[ComponentType.SeparatorIconClose] = input.SeparatorIconClose;
ComponentsRegistry[ComponentType.NumberInput] = input.NumberInput;
ComponentsRegistry[ComponentType.FileUpload] = input.FileUpload;
ComponentsRegistry[ComponentType.SelectionCoverageCards] = input.SelectionCoverageCards;
ComponentsRegistry[ComponentType.HomeSelectionCoverageCards] = input.SelectionCoverageCardsV2;
ComponentsRegistry[ComponentType.SsnOrDobRadioInput] = input.SsnOrDobRadioInput;
ComponentsRegistry[ComponentType.AdditionCard] = input.AdditionCard;
ComponentsRegistry[ComponentType.AdditionalTimeCard] = input.AdditionalTimeCard;
ComponentsRegistry[ComponentType.SalaryCard] = input.SalaryCard;
ComponentsRegistry[ComponentType.CompactCoverListTable] = input.CompactCoverListTable;
ComponentsRegistry[ComponentType.AddonAccordion] = input.AddonAccordion;
ComponentsRegistry[ComponentType.AddonAccordionBody] = input.AddonAccordionBody;
ComponentsRegistry[ComponentType.AddonAccordionFooter] = input.AddonAccordionFooter;
ComponentsRegistry[ComponentType.AddonCardModalAddButton] = input.AddonCardModalAddButton;
ComponentsRegistry[ComponentType.UpptecSearchInput] = input.UpptecSearchInput;

// Output components
ComponentsRegistry[ComponentType.Loader] = output.Loader;
ComponentsRegistry[ComponentType.ModalIframeLayout] = output.ModalIframeLayout;
ComponentsRegistry[ComponentType.ExtendedDescription] = output.ExtendedDescriptionWidget;
ComponentsRegistry[ComponentType.SectionHeader] = output.SectionHeader;
ComponentsRegistry[ComponentType.SectionSubheader] = output.SectionSubheader;
ComponentsRegistry[ComponentType.Markdown] = output.MarkdownOutput;
ComponentsRegistry[ComponentType.FlexRow] = output.FlexRowOutput;
ComponentsRegistry[ComponentType.DownloadLink] = output.DownloadLinkOutput;
ComponentsRegistry[ComponentType.PrePostPurchaseDownloadLink] = output.PrePostPurchaseDownloadLink;
ComponentsRegistry[ComponentType.Link] = output.LinkOutput;
ComponentsRegistry[ComponentType.DescriptionListRow] = output.DescriptionListRow;
ComponentsRegistry[ComponentType.GroupLogo] = shoppingCartOutput.GroupLogo;
ComponentsRegistry[ComponentType.GroupPDFDownloadLink] = shoppingCartOutput.GroupPDFDownloadLinkOutput;
ComponentsRegistry[ComponentType.StepSectionHeader] = output.StepSectionHeader;
ComponentsRegistry[ComponentType.InformationBox] = output.InformationBox;
ComponentsRegistry[ComponentType.InformationBoxWithModal] = output.InformationBoxWithModal;
ComponentsRegistry[ComponentType.Icon] = output.Icon;
ComponentsRegistry[ComponentType.CollapsedCardCounter] = output.CollapsedCardCounter;
ComponentsRegistry[ComponentType.SpacingLine] = output.SpacingLine;
ComponentsRegistry[ComponentType.CardNewTypography] = output.CardNewTypography;
ComponentsRegistry[ComponentType.QuestionnaireSummary] = output.QuestionnaireSummary;

// Register group component implementations
const GroupComponentsRegistry: IComponentsRegistry = {};

GroupComponentsRegistry[ComponentType.SummaryCard] = panel.SummaryCardWidget;
GroupComponentsRegistry[ComponentType.StepItem] = panel.StepItemOutput;
GroupComponentsRegistry[ComponentType.ContentPanel] = panel.ContentPanel;
GroupComponentsRegistry[ComponentType.StepSectionBody] = panel.StepSectionBodyOutput;
GroupComponentsRegistry[ComponentType.MultipleInputGrid] = panel.MultipleInputGrid;
GroupComponentsRegistry[ComponentType.DescriptionListPanel] = panel.DescriptionListPanelWithRouter;
GroupComponentsRegistry[ComponentType.AccordionPanel] = panel.AccordionPanel;
GroupComponentsRegistry[ComponentType.AddonCardPanel] = panel.AddonCardPanel;
GroupComponentsRegistry[ComponentType.ModalPanel] = panel.ModalPanel;
GroupComponentsRegistry[ComponentType.Questionnaire] = panel.QuestionnairePanel;
GroupComponentsRegistry[ComponentType.QuestionnairePlaceholder] = panel.QuestionnairePlaceholder;
GroupComponentsRegistry[ComponentType.PanelSectionHeader] = panel.PanelSectionHeader;
GroupComponentsRegistry[ComponentType.OptInOptOutSummary] = optInOptOutContainer.OptInOptOutSummary;
GroupComponentsRegistry[ComponentType.ExpandablePanel] = panel.ExpandablePanel;
GroupComponentsRegistry[ComponentType.FooterContainer] = journeyContainer.FooterContainer;
GroupComponentsRegistry[ComponentType.LabelPanel] = panel.LabelPanel;
GroupComponentsRegistry[ComponentType.ChildrenFieldBody] = panel.ChildrenFieldBody;
GroupComponentsRegistry[ComponentType.FloatingPanel] = panel.FloatingPanel;
GroupComponentsRegistry[ComponentType.SpacingPanel] = panel.SpacingPanel;
GroupComponentsRegistry[ComponentType.CardNew] = panel.CardNew;
GroupComponentsRegistry[ComponentType.CardNewSection] = panel.CardNewSection;
GroupComponentsRegistry[ComponentType.CoverQuestionnairePanel] = panel.CoverQuestionnairePanel;
GroupComponentsRegistry[ComponentType.AdditionalBuildingPanel] = panel.AdditionalBuildingPanel;
GroupComponentsRegistry[ComponentType.ShoppingCartSummary] = shoppingCartContainer.ShoppingCartSummary;

// Register specific containers implementation
ComponentsRegistry[ComponentType.PartnerSsnInputContainer] = shoppingCartContainer.PartnerSsnInput;
ComponentsRegistry[ComponentType.ChildrenSsnInputContainer] = shoppingCartContainer.ChildrenSsnInput;
ComponentsRegistry[ComponentType.PolicyList] = shoppingCartContainer.PolicyList;
ComponentsRegistry[ComponentType.ProductSelectionWidget] = shoppingCartContainer.ProductSelectionWidget;
ComponentsRegistry[ComponentType.SelectedProductListTotals] = shoppingCartContainer.SelectedProductListTotals;
ComponentsRegistry[ComponentType.PersonSsnNumberContainerSingle] = journeyContainer.PersonSsnInputSingleContainer;
ComponentsRegistry[ComponentType.PersonSsnNumberContainerMultiple] = journeyContainer.PersonSsnNumberContainerMultiple;
ComponentsRegistry[ComponentType.VehicleRegistrationNumberInputMultipleContainer] = journeyContainer.VehicleRegistrationNumberInputMultipleContainer;
ComponentsRegistry[ComponentType.OptOutContainer] = optInOptOutContainer.OptOutContainer;
ComponentsRegistry[ComponentType.OptInContainer] = optInOptOutContainer.OptInContainer;
ComponentsRegistry[ComponentType.AddOnCardContainer] = journeyContainer.AddOnCardContainer;
ComponentsRegistry[ComponentType.BoatAddOnCardAdvancedContainer] = journeyContainer.BoatAddOnCardAdvancedContainer;
ComponentsRegistry[ComponentType.OptInOptOutCarousel] = journeyContainer.OptInOptOutCarousel;

export {ComponentsRegistry, GroupComponentsRegistry};
