import {Button, DisplayIcon, IconRefresh, SimpleError, WarningHeaderIcon} from "@folksam-digital/ui";
import {CmsCatalogs, CmsContext, CmsProvider, ICmsContext} from "../../../../cms";
import ErrorBase from "../../../ErrorBase";
import {IErrorParams} from "../../journeyErrorTypes";
import React from "react";
import {FormattedMessage} from "react-intl";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {JourneyErrorNavButton} from "../../../../components/journey/navigation/JourneyErrorNavButton";
import {flowRight} from "lodash";
import {HeaderLayoutNew} from "../../../../components";
import {StorageKeys} from "../../../../services";
import {Classes} from "jss";
import withStyles, {Styles} from "react-jss";
import * as H from 'history';
import FormattedMarkdown from "../../../../components/FormattedMarkdown";

const styles: Styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'center'
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
    }
};

export interface IPoliciesFailedErrorProps extends IErrorParams {
    history: H.History;
    classes?: Classes<keyof typeof styles>;
}

class PoliciesFailedErrorInternal extends ErrorBase<IPoliciesFailedErrorProps> {
    private readonly containerRef: React.RefObject<any>;
    constructor(props: IPoliciesFailedErrorProps) {
        super(props);
        this.containerRef = React.createRef();
    }

    public render() {
        const {history, classes} = this.props;
        const data: IErrorParams = this.draftService.getDraft<IErrorParams>(StorageKeys.JOURNEY_ERROR_DATA_KEY);

        return (
            <CmsProvider catalog={CmsCatalogs.optInOptOutError}>
                <CmsContext.Consumer>
                    {(cmsContext: ICmsContext) => (
                        <div ref={this.containerRef} className={classes?.container}>
                            <div className={classes?.header}>
                                <HeaderLayoutNew
                                    themeId={data?.themeId ? Number(data?.themeId) : 2}
                                    journeyId={data.journeyId}
                                    formData={data?.defaultData}
                                    navButton={JourneyErrorNavButton}
                                    renderCollabBadge={false}
                                    backUrl={data?.backUrl}
                                    translations={{
                                        headerText: <FormattedMessage id={CmsHelper.withPrefix(cmsContext, "policiesFailed.header.title")}/>
                                    }}
                                    showLoader={false}
                                    hideBanner={true}
                                />
                            </div>
                            <SimpleError 
                                titleIcon={<WarningHeaderIcon/>}
                                title={<FormattedMessage id={CmsHelper.withPrefix(cmsContext, "policiesFailed.body.title")}/>} // <FormattedMessage id={CmsHelper.withPrefix(cmsContext, "policiesFailed.body.title")}/> || intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, "policiesFailed.body.title")})
                                body={<FormattedMarkdown messageKey={CmsHelper.withPrefix(cmsContext, "policiesFailed.body.description")}/>} // <FormattedMarkdown messageKey={CmsHelper.withPrefix(cmsContext, "policiesFailed.body.description")}/> || intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, "policiesFailed.body.description")})
                            >
                                <>
                                    <Button onClick={() => {history.goBack()}}>
                                        <FormattedMessage id={CmsHelper.withPrefix(cmsContext, "policiesFailed.button.title")}/>
                                        <DisplayIcon style={{marginLeft: '8px'}} icon={<IconRefresh/>}/>
                                    </Button>
                                </>
                            </SimpleError>
                        </div>
                    )}
                </CmsContext.Consumer>
            </CmsProvider>
        );
    }
};

const PoliciesFailedError = flowRight(withStyles(styles))(PoliciesFailedErrorInternal);
export {PoliciesFailedError};