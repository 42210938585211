import * as React from "react";
import {Constants, Group} from "@folksam-digital/model";
import {
    Badge,
    Button,
    CardBox,
    defaultTheme,
    DisplayIcon,
    ErrorHeaderIcon,
    MaxWidthWrapper,
    QuestionsIcon,
    Spacing,
    Step
} from "@folksam-digital/ui";
import {HeaderLayoutNew} from "../../../../components";
import ErrorBase from "../../../ErrorBase";
import {DynamicTitle} from "../../../../components/general/DynamicTitle";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {IErrorParams} from "../../journeyErrorTypes";
import {StorageKeys} from "../../../../services";
import {UrlHelper} from "@folksam-digital/services";
import {JourneyErrorNavButton} from "../../../../components/journey/navigation/JourneyErrorNavButton";
import withCmsProvider from "../../../../components/general/withCmsProvider";
import withDataAnalytics from "../../../../components/general/withDataAnalytics";
import {FormattedMessage} from "react-intl";
import flowRight from "lodash/flowRight";
import {AnalyticsType, getAnalytics} from "../../../../analytics/AnalyticsSwitch";

export interface IMemberNotFoundErrorProps extends IErrorParams {
    group: Group
}

class MemberNotFoundErrorInternal extends ErrorBase<IMemberNotFoundErrorProps> {

    public render() {

        const styles = {
            heading: {
                color: defaultTheme.colors.secondarySuccess4,
                fontWeight: defaultTheme.fontWeights.bold,
                fontSize: defaultTheme.textSizes['5'],
                width: '100%'
            },
            notice: {
                color: '#000000',
                textAlign: 'center' as const,
                fontSize: defaultTheme.textSizes.copy,
                lineHeight: defaultTheme.leading.copy,
                fontWeight: defaultTheme.fontWeights.semiBold
            },
            contactDetails: {
                display: 'flex',
                gap: 8,
                alignItems: 'center',
                justifyContent: 'flex-start',
            }
        }

        const data: IMemberNotFoundErrorProps = this.draftService.getDraft<IMemberNotFoundErrorProps>(StorageKeys.JOURNEY_ERROR_DATA_KEY);
        const params: IErrorParams = {
            journeyId: data.journeyId,
            backUrl: Constants.Journey.OptInOptOut.backUrl + data.group.name,
        }

        const headerLayoutFormData = {
            member: {
                contact: data.contact
            },
            group: data.group
        };

        return(
            <React.Fragment>
                <DynamicTitle keyId={CmsHelper.withPrefix(this.context, "title.message")}/>
                <HeaderLayoutNew
                    renderContent={this.renderHeaderInfoBox(data)}
                    themeId={1}
                    journeyId={params?.journeyId || ""}
                    formData={headerLayoutFormData}
                    navButton={JourneyErrorNavButton}
                    backUrl={params?.backUrl}
                    translations={{
                        headerText: data.headerText,
                        header: 'product.error.memberNotFound.banner.header',
                        subheader: 'product.error.memberNotFound.banner.subheader'
                    }}
                    params={{
                        icon: <ErrorHeaderIcon />,
                        bigCircle: true,
                        centered: true
                    }}
                    isErrorPage={true}
                />
                <MaxWidthWrapper compact={true}>
                    <Spacing type={"padding"} bottom={5}/>
                    <Step.SectionBody>
                        <CardBox variation={'secondary'}>
                            <div style={styles.heading}>
                                <FormattedMessage id={'product.error.memberNotFound.content.heading'} />
                            </div>
                            <Spacing type={"padding"} bottom={5}/>
                            <FormattedMessage id={'product.error.memberNotFound.content.text'} />
                            <Spacing type={"padding"} bottom={5}/>
                            <Button primary full onClick={this.onMoveToShoppingCart}>
                                <FormattedMessage id={"product.error.memberNotFound.content.buttonText"} />
                            </Button>
                        </CardBox>
                        <Spacing type={"padding"} bottom={5}/>
                        <div style={styles.notice}>
                            <FormattedMessage id={'product.error.memberNotFound.content.contactNotice'} />
                        </div>
                        <Spacing type={"padding"} bottom={5}/>
                        <CardBox variation={'primary'}>
                            <div style={{ ...styles.notice, ...styles.contactDetails }}>
                                <Badge type='secondaryInfo'
                                       icon={<DisplayIcon
                                            large={true}
                                            icon={<QuestionsIcon
                                            style={{marginBottom: 'unset'}}/>}
                                       />}
                                       circle={true}
                                       style={{width: '42px', height: '42px'}}/>
                                <FormattedMessage id={'product.error.memberNotFound.content.contactInfo'} />
                            </div>
                        </CardBox>
                        <Spacing type={"padding"} bottom={5}/>
                    </Step.SectionBody>
                </MaxWidthWrapper>
            </React.Fragment>
        );
    }

    private onMoveToShoppingCart() {
        const url = UrlHelper.getGroupJourneyUrl(Constants.Journey.ShoppingCart.Id);
        window.open(url, "_blank");
    }

    private renderHeaderInfoBox(data: IMemberNotFoundErrorProps) {
        return(
            <CardBox variation={'primary'}>
                <FormattedMessage
                    id={'product.error.memberNotFound.banner.headerInfo'}
                    values={{name: data.group.name}}/>
            </CardBox>
        );
    };
}

const MemberNotFoundError = flowRight(
    withCmsProvider("error"),
    withDataAnalytics(getAnalytics(AnalyticsType.Product))
)(MemberNotFoundErrorInternal);

export default MemberNotFoundError;
